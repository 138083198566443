// Dependencies
import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
// Hooks
import useSettings from "../hooks/useSettings";
// Components
import Header from "../components/Header";
import Card from "../components/Card";
import Dealer from "../components/Dealer";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import NewsletterForm from "../components/NewsletterForm";
import AnnotatedText from "../components/AnnotatedText";

const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);

const Introduction = ({ introduction, portrait }) => {

  // Content
  const { adviceCategories } = useSettings();
  const randomAdvice = adviceCategories.map((category) => {
    const selection = category.advice[Math.floor(Math.random() * category.advice.length)]
    return selection
  });
  const shuffledAdvice = shuffleArray(randomAdvice);
  const advice = shuffledAdvice.slice(0, 4);

  // UX
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true)
  }, [])

  return (
    <div className="intro">

      <div className="intro-text">
        <AnnotatedText className="container p1 dark" richText={introduction} />
      </div>

      {ready && (
        <Dealer>
          <div className="dealer-image">
            <img src={portrait.fluid.src} srcSet={portrait.fluid.srcSet} sizes={portrait.fluid.sizes} alt="Portrait of Sir Paul Smith" />
          </div>

          {advice.map((entry, index) =>  (
            <Card {...entry} key={`card-${entry.slug}-${index}`} />
          ))}
        </Dealer>
      )}
    </div>
  )
}

const Homepage = ({ data }) => {

  // Content
  const { introduction, aboutSummary, about, portrait, showNewsletterForm } = data.allContentfulHomepage.nodes[0];
  const { newsletterTitle, readMoreAboutTheFoundation } = useSettings();

  return (
    <Layout>
      <Header />
      <Introduction introduction={introduction} portrait={portrait} />

      <div className="container">
        <div className="grid">
          <div className="col-4">
            <AnnotatedText className="p2 has-indents" richText={about} field={aboutSummary} />
            <Link to="/about" className="dark-link outerx4">{readMoreAboutTheFoundation}</Link>
          </div>

          {showNewsletterForm && (
            <div className="col-2">
              <h2>{newsletterTitle}</h2>
              <NewsletterForm />
            </div>
          )}
        </div>
      </div>
      <div className="spacer" />
      <Footer />
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired
}

export default Homepage

export const pageQuery = graphql`
  query HomepageQuery {
    allContentfulHomepage {
      nodes {
        ## Introduction
        introduction {
          json
        }
        ## About summary
        aboutSummary {
          childMarkdownRemark {
            html
          }
        }
        about {
          json
        }
        ## portrait image
        portrait {
          fluid(maxHeight: 500) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        showNewsletterForm
      }
    }
  }
`
